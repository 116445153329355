import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';  
import { ToastContainer } from 'react-toastify';
import Main from '@modules/main/Main';
import StaticMain from '@modules/StaticMain/StaticMain';
import { useWindowSize } from '@app/hooks/useWindowSize';
import { calculateWindowSize } from '@app/utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { setWindowSize } from '@app/store/reducers/ui';
import PageNotFound from '@app/pages/Page404';
import Home from '@pages/Home'; 
import { GetAQuote, Faq, ContactUs, ThankYou } from './pages/support';
import { Overview } from './pages/for-business'; 
import { CoolingInstallation, CoolingReplacements, CoolingServiceRepair } from './pages/cooling';
import { HomeSecurityProducts, HomeSecurityProductDetail, HomeAutomation, HomeSecurityCameras, ProfessionalMonitoring, WhyChooseSlominsShield } from './pages/security';
import { AboutUsOverview, CustomerReviews, Locations, ReferallProgram, Blog, BlogDetails,ACTNOWBLOGFORM } from './pages/about-us';
import { TermOfUse, PrivacyPolicy, License, Spotify, PPCShopSpecials, FreeDoorbellCamera, FreeEquipmentAndInstallationNow, Audacy, NextDayInstallation, PPCGmAffordablesecurity, IHeartRadio, Aragon, Pandora, PPCFreeSecuritySystem, AffordableMonitoring, PPCSocialFamilySecurity, PPCWirelessSecurity, IHeart, PPCSocialPropertySecurity, PPCSocialHeirloomSecurity, PPCGMFreeDoorbell, PPCFreeDoorbell, PPCOrderAfforableSecurity, PPCFreeInstall, CompareOil, AlarmSystemSunset } from './pages/static-page';  
import { GetIPAddress } from './services/GetIPAddress';
const App = () => {
  const windowSize = useWindowSize();
  const screenSize = useSelector((state: any) => state.ui.screenSize);
  const dispatch = useDispatch();
  useEffect(() => {
    const size = calculateWindowSize(windowSize.width);
    if (screenSize !== size) {
      dispatch(setWindowSize(size));
    }
  }, [windowSize]);  
  
  useEffect(() => { 
    const fetchIPAddress = async () => {
      try {
        const IPAddress = await GetIPAddress();
        localStorage.setItem('IPAddress', IPAddress); 
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    }; 
    fetchIPAddress(); 
    let IPAddress = localStorage.getItem('IPAddress');
      console.log("IP add",IPAddress);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Home />} />
        <Route path="/" element={<Main />}> 
          <Route path="/home-security-products" element={<HomeSecurityProducts />} />
          <Route path="/product/:slug" element={<HomeSecurityProductDetail />} />
          <Route path="/home-security/home-automation" element={<HomeAutomation />} />
          <Route path="/home-security/home-security-cameras" element={<HomeSecurityCameras />} />
          <Route path="/professional-monitoring" element={<ProfessionalMonitoring />} />
          <Route path="/why-choose-the-slomins-shield" element={<WhyChooseSlominsShield />} />
          <Route path="/about-us" element={<AboutUsOverview />} />
          <Route path="/locations" element={<Locations />} />
          <Route path="/customer-reviews" element={<CustomerReviews />} />
          <Route path="/referral-program" element={<ReferallProgram />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/:slug" element={<BlogDetails />} /> 
          <Route path="/category/:categoryname" element={<Blog />} />
          <Route path="/blog/page/:id" element={<Blog />} />
          <Route path="/for-business" element={<Overview />} />
          <Route path="/cooling-installation" element={<CoolingInstallation />} />
          <Route path="/cooling-replacements" element={<CoolingReplacements />} />
          <Route path="/cooling-service-repair" element={<CoolingServiceRepair />} />
          <Route path="/get-a-quote" element={<GetAQuote />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/term-of-use" element={<TermOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/license" element={<License />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/actnow-blog-form" element={<ACTNOWBLOGFORM />} />
          <Route path="*" element={<PageNotFound />} />
          {/* <Route path="/404" element={<PageNotFound />} /> */}
        </Route>

        {/* SeedProd Landing pages */}
        <Route path="" element={<StaticMain />}>           
            <Route path="/spotify" element={<Spotify />} />
            <Route path="/ppc-shopspecials" element={<PPCShopSpecials />} />
            <Route path="/free-doorbell-camera" element={<FreeDoorbellCamera />} />
            <Route path="/free-equipment-and-installation-now" element={<FreeEquipmentAndInstallationNow />} />
            <Route path="/audacy" element={<Audacy />} />
            <Route path="/next-day-installation" element={<NextDayInstallation />} />
            <Route path="/ppc-gm-affordablesecurity" element={<PPCGmAffordablesecurity />} />
            <Route path="/iheartradio" element={<IHeartRadio />} />
            <Route path="/aragon" element={<Aragon />} />
            <Route path="/pandora" element={<Pandora />} />
            <Route path="/ppc-freesecuritysystem" element={<PPCFreeSecuritySystem />} />
            <Route path="/affordablemonitoring" element={<AffordableMonitoring />} />
            <Route path="/ppc-social-familysecurity" element={<PPCSocialFamilySecurity />} />
            <Route path="/ppc-wirelesssecurity" element={<PPCWirelessSecurity />} />
            <Route path="/iheart" element={<IHeart />} />
            <Route path="/ppc-social-propertysecurity" element={<PPCSocialPropertySecurity />} />
            <Route path="/ppc-social-heirloomsecurity" element={<PPCSocialHeirloomSecurity />} />
            <Route path="/ppc-gm-freedoorbell" element={<PPCGMFreeDoorbell />} />
            <Route path="/ppc-freedoorbell" element={<PPCFreeDoorbell />} />
            <Route path="/ppc-order-afforablesecurity" element={<PPCOrderAfforableSecurity />} />
            <Route path="/ppc-freeinstall" element={<PPCFreeInstall />} />
            <Route path="/compare-oil" element={<CompareOil />} />
            <Route path="/3g-alarm-system-sunset" element={<AlarmSystemSunset />} />
        </Route> 

      </Routes>
      <ToastContainer
        autoClose={3000}
        draggable={false}
        position="top-right"
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnHover
      />
    </BrowserRouter>
  );
};

export default App;
